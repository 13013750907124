import React, { useEffect } from 'react';
import '../styles/collapsable.scss';
import anime from 'animejs/lib/anime.es.js';

import AOS from 'aos';
import 'aos/dist/aos.css';






const Collapsable = ({children, title, number, list}) => {

    useEffect(() => {
        AOS.init();

    })

    return (
    <div className="border-t-2 relative" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="400">
        <span className="line-overlay" />
        <div className="collapsible flex flex-row justify-between">
            <div className=" py-6 flex flex-col justify-between">
                <p className="text-soGray transition-colors ease-in-out duration-300">{number}</p>
                <h3 className="text-xl"> {title} </h3>
                <p className="hidden md:inline text-soGray md:mr-8">{children}</p>
            </div>
            <div className="flex flex-col justify-center">
                <span className="arrow">
                    <span/>
                    <span/>
                </span>
            </div>
        </div>
        <div className="content text-soGray transition-colors ease-in-out duration-300">
            <p className="md:hidden">{children}</p>
            <ul className="list-disc py-6">
                {list.map((item,key) => (
                    <li key={key}>
                        • {item}
                    </li>
                ))}
            </ul>
        </div>
    </div>
)};

export default Collapsable;