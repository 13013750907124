import React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Collapsable from '../components/collapsable'
import ProjectPreview from '../components/projectPreview'

import serviceData from '../data/services'
//import Logo from "../svgs/stepone_logo.inline.svg"

import AniLink from "gatsby-plugin-transition-link/AniLink";

import '../styles/index.scss'
import CookieConsent from "../components/cookieConsent";
import lottie from 'lottie-web'

// import lottie from '../animation/lottie'
// import '../animation/data.json'
//import {animationData} from '../animation/animationData'
import animationData from '../animation/testData.json'

//import video1 from '../videos/video1.mp4'
//import anime from 'animejs/lib/anime.es.js';



class IndexPage extends React.Component {


  componentDidMount() {

    if (typeof window !== `undefined`) {
      var lottieContainer = document.querySelector('.bg-illu')

      const anim = lottie.loadAnimation({
        container: document.querySelector('.bg-illu'), // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autolay: true,
        animationData: animationData,
      });
      //return () => anim.destroy();
    }

    if (typeof window !== `undefined`) {
      var coll = document.getElementsByClassName("collapsible");
      var i;

      for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function() {
          console.log('done')
          this.classList.toggle("active");
          var content = this.nextSibling;
          this.children[0].children[0].classList.toggle('text-soYellow')
          this.children[1].children[0].classList.toggle('arrow-active')
          this.parentNode.children[0].classList.toggle('line-overlay-active')
          if (content.style.maxHeight){
            content.style.maxHeight = null;
            
          } else {
            content.style.maxHeight = content.scrollHeight + "px";
            
          }
        });
      }
    }
  }

      

        
        

  render() {
    return(
      <Layout>
      <SEO title="home" />
      <div id="container">
        <div id="title-section" className="flex flex-col lg:flex-row justify-center lg:justify-between items-center md:mb-16">
          <div id="inner-title" className="flex flex-col justify-between lg:justify-center transform transition-all duration-300 inner-title lg:w-3/4 xl:w-full">
            <div></div>
            <h1 className="text-3xl pb-4 " id="main-title" data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000">
              <span ><span className="underline" >Ein Kreativstudio,</span> das Lösungen entwickelt, die Unternehmen weiterbringen und Menschen begeistern. </span>
            </h1>
            <p className="text-soGray pb-4" data-aos="fade-up" data-aos-delay="800" data-aos-duration="1600">
              Südlich von Wien, mit Remote-Projekten <span className="underline">weltweit</span>.
            </p>
          </div>
          <div className="hidden lg:block lg:w-3/4 xl:w-2/5 bg-illu ml-16 pb-8" data-aos="fade-in" data-aos-delay="2000" data-aos-duration="1000">

          </div>
        </div>
  


      <div id="image-vid-container" className="overflow-hidden md:mb-16" data-aos="fade-up" data-aos-delay="500" data-aos-duration="2000" 
        style={{
          backgroundImage: 'url(' + this.props.data.contentfulAsset.file.url + ')'
        }}>
          {/*<video id="background-video" loop autoPlay muted className="">
            <source src={video1} type="video/mp4" />
    </video>  */}
        </div>
        


  
        <div id="services-section" className="pb-0 md:pb-24 flex flex-col md:flex-row md:justify-between md:mt-10">
          <h2 className="text-2xl mt-16 md:mt-8 md:text-3xl" data-aos="fade-up" data-aos-duration="1000">Unsere Services.</h2>
        

          <div className="my-10 md:w-3/5">
            <Collapsable number="01" title="Branding" list={serviceData.brandingList} data-aos="fade-up" data-aos-duration="1000">
              {serviceData.brandingText}
            </Collapsable>
            <Collapsable number="02" title="Werbung" list={serviceData.adList} data-aos="fade-up" data-aos-duration="1000">
              {serviceData.adText}
            </Collapsable>
            <Collapsable number="03" title="Strategie" list={serviceData.strategyList} data-aos="fade-up" data-aos-duration="1000">
              {serviceData.strategyText}
            </Collapsable>
            <Collapsable number="04" title="Betreuung" list={serviceData.maintainList} data-aos="fade-up" data-aos-duration="1000">
              {serviceData.maintainText}
            </Collapsable>
            <div className="w-full border-b-2" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="400"/>
          </div>

        </div>

        <div id="projects-section" className="pb-12">
          <div id="projects-title-section" className="flex flex-col mb-6 md:flex-row md:justify-between md:items-baseline">
            <h2 className="text-2xl mt-16 md:mt-8 md:text-3xl mb-4" data-aos="fade-in" data-aos-duration="700">Unsere Arbeiten für <br/> glückliche Kunden.</h2>
            <AniLink className="animated-arrow" to="/arbeiten" data-aos="fade-in" data-aos-duration="700" paintDrip hex="#000">
              <span className="the-arrow -left">
                <span className="shaft" />
              </span>
              <span className="main">
                <span className="text">
                Entdecken
                </span>
                <span className="the-arrow -right">
                  <span className="shaft" />
                </span>
              </span>
            </AniLink>
          </div>
          <div className="flex flex-col lg:flex-row lg:justify-between flex-wrap">
            {this.props.data.allContentfulProjekt.nodes.map(
              (projekt, key) => (
                <ProjectPreview
                  key={key}
                  urlName={projekt.projekttitel.replace(/\s/g, "_").toLowerCase()}
                  title={projekt.projekttitel}
                  customer={projekt.kunde}
                  category={
                    projekt.kategorie.map((kategorie, index) => ((index ? ', ' : '') + kategorie.kategorie))}
                  year={projekt.jahr}
                  bg_img={projekt.titelbild.file.url}
                />
              )
            )}
            
          </div>
        </div>
          
      </div>
      
    </Layout>
    )
  }
}

export const query = graphql`
  {
    allContentfulProjekt(limit: 4) {
      nodes {
        id
        jahr
        bilder {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
          title
        }
        createdAt
        einleitungstitel
        einleitungstext {
          einleitungstext
        }
        projekttitel
        kunde
        kategorie {
          kategorie
        }
        titelbild {
          file {
            url
          }
        }
      }
    }
    contentfulAsset(id: {eq: "9af16de4-5ac1-5b93-a2c0-8ffb22148075"}) {
      id
      title
      file {
        url
      }
    }
  }
`


export default IndexPage
