import React from 'react';

import '../styles/projectpreview.scss'
import AniLink from "gatsby-plugin-transition-link/AniLink";

import AOS from 'aos';
import 'aos/dist/aos.css';

class ProjectPreview extends React.Component {

    componentDidMount() {
        AOS.init()
    }


    render() {
        return(
            <AniLink to={`/arbeiten/${this.props.urlName}`} className="my-4 width-48 odd-margin size-transform" paintDrip hex="#000" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="400">

                <div className="size-transform">

                    <div id="project-preview-container" className="relative w-auto">
                        {/* das folgende <div> wird für das preview-bild verwendet*/}
                        <div className="top-0 left-0 h-full w-full z-10 bg-cover" style={{ backgroundImage: `url("https://${this.props.bg_img.slice(2)}` }}></div>
                        {/* das folgende <div> wird für die inner-shadow überlagerung verwendet*/}
                        <div className="top-0 left-0 h-full w-full z-20 box-shadow-overlay absolute"></div>
                        
                        <div id="project-description-container" className="flex flex-col justify-end z-30 text-white p-6 top-0 left-0 w-full h-full absolute" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="800">
                            <div className="relative  mb-4 inline-block flex-shrink">
                                <h3 className="text-3xl inline-block animation-underline">{this.props.title}</h3>
                            </div>
                            <div id="project-details-container" className="flex flex-row justify-start lg:justify-start">
                                <div id="kunde" className="flex flex-col mr-4 md:mr-6">
                                    <span className="font-medium">Kunde</span>
                                    <span className="font-light">{this.props.customer}</span>
                                </div>
                                <div id="kategorie" className="flex flex-col mx-4 md:mx-6">
                                    <span className="font-medium">Kategorie</span>
                                    <span className="font-light">{this.props.category}</span>
                                </div>
                                <div id="jahr" className="flex flex-col ml-4 md:ml-6">
                                    <span className="font-medium">Jahr</span>
                                    <span className="font-light">{this.props.year}</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>

            </AniLink>
        )
    }
}

export default ProjectPreview;